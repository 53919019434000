import { FC, useMemo } from 'react';
import { TFunction } from 'i18next';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import type { ITimesheetDurationCellRendererProps } from 'components/cellRenderers/timetracking/TimesheetDurationCellRenderer/TimesheetDurationCellRenderer.props';
import {
  TimesheetDurationStyled,
  TimesheetSeparatorStyled,
} from 'components/cellRenderers/timetracking/TimesheetDurationCellRenderer/TimesheetDurationCellRenderer.styled';

const getTimetrackingDurationString = (
  t: TFunction,
  start?: string,
  end?: string
) => {
  const startMoment = moment(start);
  const endMoment = moment(end);
  const durationHours = endMoment.diff(startMoment, 'hours');
  const durationTotalMinutes = endMoment.diff(startMoment, 'minutes');
  const durationMinutes = durationTotalMinutes % 60;

  return `${durationHours}${t('hourShort')} ${durationMinutes}${t(
    'minuteShort'
  )}`;
};

const displayDurationAsTime = (t: TFunction, durationHours: number) => {
  const duration = parseFloat(durationHours.toFixed(4)); //use 4 decimal places same as in backend

  const hours = Math.floor(duration).toString();
  const minutes = Math.round((duration * 60) % 60).toString();

  return `${hours}${t('hourShort')} ${minutes}${t('minuteShort')}`;
};

export const TimesheetDurationCellRenderer: FC<
  ITimesheetDurationCellRendererProps
> = ({ start, end, enableSeparator = false, durationHours }) => {
  const { t } = useTranslation('general');

  const duration = useMemo(
    () =>
      durationHours
        ? displayDurationAsTime(t, durationHours)
        : getTimetrackingDurationString(t, start, end),
    [durationHours, end, start, t]
  );

  return (
    <>
      <TimesheetDurationStyled>{duration}</TimesheetDurationStyled>
      {enableSeparator && <TimesheetSeparatorStyled />}
    </>
  );
};
