import { FC } from 'react';
import { useParams } from 'react-router';
import { Loader, Tabs } from 'team-hero-ui';
import { useTranslation } from 'react-i18next';
import { withErrorBoundary } from '@sentry/react';

import type { TMissionDetailsView } from './ClientLoginMissionDetails.types';
import MissionDetailsHeader from 'components/ClientLoginMissionDetails/MissionDetailsHeader';
import { useSearchUrlParams } from 'hooks/useSearchUrlParams.hook';
import {
  ClientLoginMissionDetailsStyled,
  ErrorWrapper,
} from './ClientLoginMissionDetails.styled';
import MissionJobsTab from 'components/ClientLoginMissionDetails/Tabs/MissionJobsTab';
import MissionTimesheetsTab from 'components/ClientLoginMissionDetails/Tabs/MissionTimesheetsTab';
import MissionOverviewTab from 'components/ClientLoginMissionDetails/Tabs/MissionOverviewTab';
import MissionRatingsTab from 'components/ClientLoginMissionDetails/Tabs/MissionRatingsTab';
import MissionApplicationsTab from 'components/ClientLoginMissionDetails/Tabs/MissionApplicationsTab';
import MissionDocumentsTab from 'components/ClientLoginMissionDetails/Tabs/MissionDocumentsTab';
import { useGetProjectMissionItemQuery } from 'services/teamHeroApi.service';
import FeatureFlag from 'components/FeatureFlag/FeatureFlag';
import { useFlags } from 'components/FeatureFlag/useFlags.hook';
import LayoutNoPermission from 'components/LayoutNoPermission';

type TUrlParams = {
  id: string;
};

const ClientLoginMissionDetails: FC = withErrorBoundary(
  () => {
    const { t } = useTranslation('general');

    const { id } = useParams<TUrlParams>();
    const selectedId = parseInt(id || '0', 10);

    const {
      isError,
      isLoading,
      data: mission,
    } = useGetProjectMissionItemQuery(
      {
        id: selectedId,
      },
      { skip: !id || selectedId === 0 }
    );

    const {
      selectedSearchParam: selectedTabValue,
      onSetSearchParam: onSelectTab,
    } = useSearchUrlParams<TMissionDetailsView>({ defaultValue: 'overview' });

    const { isFeatureFlagEnabled } = useFlags();

    if (isError) {
      throw new Error(t('errors:errorOccurred'));
    }

    if (isLoading) {
      return <Loader />;
    }

    if (!mission) {
      return <div>{t('noResultsFound')}</div>;
    }

    if (!mission.enableClientAccess) {
      throw new Error(t('errors:accessDenied'));
    }

    return (
      <ClientLoginMissionDetailsStyled>
        <MissionDetailsHeader mission={mission}>
          <Tabs.Tabs<TMissionDetailsView>
            value={selectedTabValue}
            onChange={onSelectTab}
            isSticky
          >
            <Tabs.Tab<TMissionDetailsView>
              value='overview'
              label={t('details.overview')}
              dataTestId={'contact-details-overview-tab'}
            />
            {mission.enableClientJobs && (
              <Tabs.Tab<TMissionDetailsView>
                value='jobs'
                label={t('details.jobs')}
                dataTestId={'contact-details-jobs-tab'}
              />
            )}
            {mission.enableClientTimetrackings && (
              <Tabs.Tab<TMissionDetailsView>
                value='timetrackings'
                label={t('details.timetrackings')}
                dataTestId={'contact-details-timetrackings-tab'}
              />
            )}
            {isFeatureFlagEnabled('clientRatings') && (
              <Tabs.Tab<TMissionDetailsView>
                value='ratings'
                label={t('details.ratings')}
                dataTestId={'contact-details-ratings-tab'}
              />
            )}
            {isFeatureFlagEnabled('clientPool') && (
              <Tabs.Tab<TMissionDetailsView>
                value='applications'
                label={t('details.applications')}
                dataTestId={'contact-details-applications-tab'}
                wrapped
              />
            )}
            {isFeatureFlagEnabled('clientDocuments') && (
              <Tabs.Tab<TMissionDetailsView>
                value='documents'
                label={t('details.documents')}
                dataTestId={'contact-details-documents-tab'}
                wrapped
              />
            )}
          </Tabs.Tabs>
          <Tabs.TabPanel<TMissionDetailsView>
            value={selectedTabValue}
            panelValue='overview'
            index={0}
          >
            <MissionOverviewTab mission={mission} />
          </Tabs.TabPanel>
          {mission.enableClientJobs && (
            <Tabs.TabPanel<TMissionDetailsView>
              value={selectedTabValue}
              panelValue='jobs'
              index={1}
            >
              <MissionJobsTab mission={mission} />
            </Tabs.TabPanel>
          )}
          {mission.enableClientTimetrackings && (
            <Tabs.TabPanel<TMissionDetailsView>
              value={selectedTabValue}
              panelValue='timetrackings'
              index={2}
            >
              <MissionTimesheetsTab
                filterPropertyName='shift.mission'
                id={mission.id}
                projectId={mission.project.id}
              />
            </Tabs.TabPanel>
          )}
          <FeatureFlag flag='clientRatings'>
            <Tabs.TabPanel<TMissionDetailsView>
              value={selectedTabValue}
              panelValue='ratings'
              index={3}
            >
              <MissionRatingsTab missionId={mission.id} />
            </Tabs.TabPanel>
          </FeatureFlag>
          <FeatureFlag flag='clientPool'>
            <Tabs.TabPanel<TMissionDetailsView>
              value={selectedTabValue}
              panelValue='applications'
              index={4}
            >
              <MissionApplicationsTab missionId={mission.id} />
            </Tabs.TabPanel>
          </FeatureFlag>
          <FeatureFlag flag='clientDocuments'>
            <Tabs.TabPanel<TMissionDetailsView>
              value={selectedTabValue}
              panelValue='documents'
              index={5}
            >
              <MissionDocumentsTab missionId={mission.id} />
            </Tabs.TabPanel>
          </FeatureFlag>
        </MissionDetailsHeader>
      </ClientLoginMissionDetailsStyled>
    );
  },
  {
    fallback: ({ eventId, resetError }) => (
      <ErrorWrapper>
        <LayoutNoPermission eventId={eventId} resetError={resetError} />
      </ErrorWrapper>
    ),
  }
);

export default ClientLoginMissionDetails;
