import { FC } from 'react';
import { Draggable } from '@hello-pangea/dnd';

import Avatar from 'components/Avatar';
import {
  ApplicationAreaHeaderStyled,
  ApplicationAreaStyled,
  ApplicationDroppableAreaStyled,
  AvatarDraggableStyled,
} from './ApplicationArea.styled';
import type { IApplicationAreaProps } from './ApplicationArea.types';

const ApplicationArea: FC<IApplicationAreaProps> = ({
  icon,
  title,
  type,
  applications,
  provided,
  snapshot,
  onAvatarClick,
}) => {
  return (
    <ApplicationAreaStyled
      $isDraggingOver={snapshot.isDraggingOver}
      $isNoFit={type === 'nofit'}
    >
      <ApplicationAreaHeaderStyled>
        {icon} {title}
      </ApplicationAreaHeaderStyled>
      <ApplicationDroppableAreaStyled
        ref={provided.innerRef}
        {...provided.droppableProps}
      >
        <>
          {applications.map((application, index) => (
            <Draggable
              draggableId={application.id.toString()}
              key={application.id}
              index={index}
            >
              {(draggableProvided) => (
                <AvatarDraggableStyled
                  $isNoFit={type === 'nofit'}
                  ref={draggableProvided.innerRef}
                  {...draggableProvided.dragHandleProps}
                  {...draggableProvided.draggableProps}
                  onClick={() => onAvatarClick(application)}
                >
                  <Avatar
                    sourceType='file'
                    size={60}
                    images={application.contact.avatar || ''}
                    firstName={application.contact.firstName}
                    lastName={application.contact.lastName}
                    thumbnailSize='small'
                    key={application.id}
                  />
                </AvatarDraggableStyled>
              )}
            </Draggable>
          ))}
          {provided.placeholder}
        </>
      </ApplicationDroppableAreaStyled>{' '}
    </ApplicationAreaStyled>
  );
};

export default ApplicationArea;
