import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Theme } from 'team-hero-ui';

import type { IContactDocument } from 'interfaces/ContactDocument.interface';
import type { ICompanyDocument } from 'interfaces/CompanyDocument.interface';
import type { IApplicationDocument } from 'interfaces/ApplicationDocument.interface';

export type TStatusColor = 'success' | 'error' | 'info' | 'warning' | '';

export type TMessageTranslationKey =
  | 'signed'
  | 'missing'
  | 'requireApproval'
  | 'requireSign'
  | 'expired'
  | '';

interface IUseDocumentStatusOutcome {
  type: TStatusColor;
  translationKey: TMessageTranslationKey;
  isSigned: boolean;
  isWaitingToApproval: boolean;
  isWaitingForUserAction: boolean;
  isExpired: boolean;
  statusColor: string;
  statusLabel: string;
}

const statusColors: { [key: string]: string } = {
  success: Theme.colors.primary.green,
  error: Theme.colors.primary.red,
  info: Theme.colors.others.blue.blue3,
  warning: Theme.colors.greyShades.grey2,
};

export const useDocumentStatus = (
  document: IContactDocument | ICompanyDocument | IApplicationDocument | null
): IUseDocumentStatusOutcome => {
  const [statusColorName, setStatusColorName] = useState<TStatusColor>('');
  const [translationKey, setTranslationKey] =
    useState<TMessageTranslationKey>('');

  const { t } = useTranslation();

  const { isExpired, isApproved, isMissing, signatureStatus } = document || {};

  const isSigned: boolean = useMemo(
    () => Boolean(!isExpired && (isApproved || signatureStatus === 'signed')),
    [isExpired, isApproved, signatureStatus]
  );

  const isWaitingToApproval: boolean = useMemo(
    () => !isApproved && !isMissing && signatureStatus !== 'waitingForContact',
    [isApproved, isMissing, signatureStatus]
  );

  const isWaitingForUserAction: boolean = useMemo(
    () => signatureStatus === 'waitingForContact',
    [signatureStatus]
  );

  const isExpiredDocument: boolean = useMemo(
    () => Boolean(isExpired) && !isWaitingToApproval && !isWaitingForUserAction,
    [isExpired, isWaitingForUserAction, isWaitingToApproval]
  );

  const statusColor: string = useMemo(
    () => statusColors[statusColorName],
    [statusColorName]
  );

  useEffect(() => {
    let color: TStatusColor = '';
    let msgKey: TMessageTranslationKey = '';
    if (isMissing || isWaitingForUserAction) {
      color = 'error';
      if (isMissing) msgKey = 'missing';
      else msgKey = 'requireSign';
    } else if (isSigned) {
      color = 'success';
      msgKey = 'signed';
    } else if (isWaitingToApproval) {
      color = 'info';
      msgKey = 'requireApproval';
    } else if (isExpiredDocument) {
      color = 'warning';
      msgKey = 'expired';
    }
    setStatusColorName(color);
    setTranslationKey(msgKey);
  }, [
    isSigned,
    isMissing,
    isWaitingForUserAction,
    isWaitingToApproval,
    isExpiredDocument,
  ]);

  return {
    type: statusColorName,
    translationKey,
    isSigned,
    isWaitingToApproval,
    isWaitingForUserAction,
    isExpired: isExpiredDocument,
    statusColor,
    statusLabel: t(`work.mission.details.documents.statuses.${translationKey}`),
  };
};
