import { TFunction } from 'i18next';

import type { IFilterDefinition } from 'interfaces/Filters.interface';

interface ICompanyDocumentsFiltersFactoryProps {
  t: TFunction;
}

export const companyDocumentsFiltersFactory = ({
  t,
}: ICompanyDocumentsFiltersFactoryProps): IFilterDefinition[] => {
  return [
    {
      key: 'name',
      customApiKey: 'template.title',
      label: t('table.filter.labels.title'),
      filterType: 'input',
    },
    {
      key: 'start',
      label: t('filters.labels.startDate'),
      filterType: 'fromToDate',
      dateRangePickerData: {
        dateRangeType: 'startEnd',
        keyFrom: 'start',
        keyTo: 'start',
        fromLabel: t('table.filter.labels.from'),
        toLabel: t('table.filter.labels.till'),
      },
    },
    {
      key: 'end',
      label: t('filters.labels.endDate'),
      filterType: 'fromToDate',
      dateRangePickerData: {
        dateRangeType: 'startEnd',
        keyFrom: 'end',
        keyTo: 'end',
        fromLabel: t('table.filter.labels.from'),
        toLabel: t('table.filter.labels.till'),
      },
    },
  ];
};
