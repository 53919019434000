import { StyleSheet } from '@react-pdf/renderer';
import { Theme } from 'team-hero-ui';

export default StyleSheet.create({
  page: {
    fontFamily: 'Helvetica',
    flexDirection: 'column',
    backgroundColor: 'white',
    padding: 20,
    paddingBottom: 60,
    fontSize: 11,
    color: Theme.colors.greyShades.grey1,
  },
  fullPage: {
    fontFamily: 'Helvetica',
    flexDirection: 'column',
    backgroundColor: 'white',
    fontSize: 11,
    color: Theme.colors.greyShades.grey1,
  },
  pageBackground: {
    top: 0,
    left: 0,
    position: 'absolute',
    minWidth: '100%',
    minHeight: '100%',
    height: '100%',
    width: '100%',
  },
  section: {
    padding: 10,
    flexGrow: 1,
  },
  fontXS: {
    fontSize: 12,
  },
  fontS: {
    fontSize: 16,
  },
  fontM: {
    fontSize: 20,
  },
  fontL: {
    fontSize: 24,
  },
  fontXL: {
    fontSize: 28,
  },
  fontXXL: {
    fontSize: 40,
  },
  fontBold: {
    fontWeight: 600,
  },
  fontGrey: {
    color: Theme.colors.greyShades.grey2,
  },
  mb: {
    marginBottom: 20,
  },
  mr: {
    marginRight: 20,
  },
  mrL: {
    marginRight: 40,
  },
  paddingL: {
    padding: 40,
  },
  paddingXL: {
    padding: 60,
  },
  flexRow: {
    display: 'flex',
    flexDirection: 'row',
  },
  spaceBetween: {
    justifyContent: 'space-between',
    flexWrap: 'nowrap',
  },
  flexCol: {
    display: 'flex',
    flexDirection: 'column',
  },
  italics: {
    fontStyle: 'italic',
  },
  flexCenter: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
});
