import { FC, MouseEvent } from 'react';
import { OverflowList, Tooltip, Tag } from 'team-hero-ui';

import { EmptyCell } from 'components/cellRenderers/EmptyCellRenderer/EmptyCellRenderer';
import { preventEventWrapper } from 'helpers/events/preventEventWrapper.helper';
import {
  TagsCellContainerStyled,
  TagWrapperStyled,
} from './TagsCellRenderer.styled';

interface ITagsCellRendererProps {
  tags: string[];
  id?: number;
  onRowClick?: (id: number) => void;
}

export const TagsCellRenderer: FC<ITagsCellRendererProps> = ({
  tags,
  onRowClick,
  id,
}) => {
  const handleClick = (event: MouseEvent) => {
    if (onRowClick && id) {
      preventEventWrapper(event, () => onRowClick(id));
    }
  };

  if (!tags || !tags.length) return <EmptyCell />;

  return (
    <TagsCellContainerStyled onClick={handleClick}>
      <OverflowList
        collapseFrom='end'
        items={tags}
        itemRenderer={(item) => (
          <TagWrapperStyled key={item}>
            <Tag fontSize={12}>{item}</Tag>
          </TagWrapperStyled>
        )}
        overflowRenderer={(items: string[]) => (
          <Tooltip
            styleType='right'
            title={items.map((item) => (
              <TagWrapperStyled key={item}>
                <Tag fontSize={12}>{item}</Tag>
              </TagWrapperStyled>
            ))}
          >
            <TagWrapperStyled>
              <Tag fontSize={12} key='see more'>
                +{items.length}
              </Tag>
            </TagWrapperStyled>
          </Tooltip>
        )}
      />
    </TagsCellContainerStyled>
  );
};
