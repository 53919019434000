import { FC, useMemo, useState } from 'react';
import moment from 'moment';

import type {
  IMissionTimesheetSettingState,
  IMissionTimesheetTabProps,
} from './MissionTimesheetTab.types';
import { useGetProjectMissionItemQuery } from 'services/teamHeroApi.service';
import { TimesheetsListTable } from './TimesheetList/TimesheetList';
import RangeTimelineControls from 'components/RangeTimelineControls';

const MissionTimesheetTab: FC<IMissionTimesheetTabProps> = ({
  filterPropertyName,
  id,
  projectId,
}) => {
  const isMissionFilter = filterPropertyName === 'shift.mission';

  const { currentData: missionData } = useGetProjectMissionItemQuery(
    { id },
    { skip: !isMissionFilter }
  );

  const startDate = missionData?.start
    ? moment(missionData?.start)
    : moment().startOf('isoWeek');

  const endDate = missionData?.start
    ? moment(missionData?.start).add(1, 'week')
    : startDate.clone().endOf('isoWeek');

  const defaultWorkplannerSettingsState: IMissionTimesheetSettingState = {
    startDate,
    endDate,
    timelineSize: '1week',
    viewMode: 'list',
  };

  const [timesheetSettings, setTimesheetSettings] =
    useState<IMissionTimesheetSettingState>(defaultWorkplannerSettingsState);

  const centerHeader = useMemo(
    () => (
      <RangeTimelineControls
        timelineSettings={timesheetSettings}
        setTimelineSettings={setTimesheetSettings}
        timelineSizeItems={['customRange']}
        defaultMaxDayDifference={9999}
      />
    ),
    [timesheetSettings]
  );

  return (
    <TimesheetsListTable
      id={id}
      projectId={projectId}
      filterPropertyName={filterPropertyName}
      timesheetSettings={timesheetSettings}
      timesheetCenterHeader={centerHeader}
    />
  );
};

export default MissionTimesheetTab;
