import { FC } from 'react';
import { Image } from '@react-pdf/renderer';

import type { IPdfImageProps } from './PdfImage.types';
import { getToken } from 'services/user/user.helper';

const PdfImage: FC<IPdfImageProps> = ({
  isFixed,
  isPublicUrl,
  src,
  style,
  isLocal,
}) => {
  const getLogoUrlBasePath = (): string => {
    if (
      import.meta.env.MODE === 'development' ||
      import.meta.env.MODE === 'mobile'
    ) {
      return import.meta.env.VITE_API_URI || '';
    }
    return '';
  };
  const token = getToken();

  return (
    <Image
      style={style}
      src={
        isLocal
          ? src
          : {
              uri: getLogoUrlBasePath() + src,
              method: 'GET',
              headers: isPublicUrl ? {} : { Authorization: `Bearer ${token}` },
            }
      }
      fixed={isFixed}
    />
  );
};

export default PdfImage;
