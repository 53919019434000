import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import type { ICompanyDocumentPdfButtonProps } from './CompanyDocumentPdfButton.types';
import useSavePdfFile from 'hooks/useSavePdfFile.hook';
import PdfDownloadButton from 'components/PdfDocuments/common/PdfDownloadButton';
import { useCurrency } from 'hooks/numbers/useCurrency.hook';
import useGetCompanyDocumentPdfLayout from './useGetCompanyDocumentPdfLayout.hook';

const CompanyDocumentPdfButton: FC<ICompanyDocumentPdfButtonProps> = ({
  document,
  fileName,
}) => {
  const { t } = useTranslation('general');
  const { isLoading, savePdfFile } = useSavePdfFile();
  const { format } = useCurrency();

  const { getCompanyDocumentPdfLayout } = useGetCompanyDocumentPdfLayout();

  const onDownloadButtonClick = async () => {
    await savePdfFile({
      getPdfLayout: async () =>
        await getCompanyDocumentPdfLayout({
          document,
          currencyFormatter: format,
          t,
        }),
      fileName,
    });
  };

  return (
    <PdfDownloadButton
      disabled={!document || isLoading}
      isLoading={isLoading}
      onClick={onDownloadButtonClick}
    />
  );
};

export default CompanyDocumentPdfButton;
