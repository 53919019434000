import { TFunction } from 'i18next';
import Joi, { AnySchema } from 'joi';
import moment, { Moment } from 'moment';

export const isValidDate = (value: Moment | null) => {
  if (value && moment(value).isValid()) {
    return true;
  }
  return false;
};

const getDateValidation =
  (isRequired = true) =>
  (value: Moment | null, helpers: Joi.CustomHelpers<unknown>) => {
    if (!value && !isRequired) {
      return value;
    }
    return isValidDate(value) ? value : helpers.error('any.required');
  };

export const dateValidationStrategy = (
  t: TFunction,
  isRequired = true
): AnySchema =>
  isRequired
    ? Joi.required()
        .custom(getDateValidation(isRequired))
        .messages({
          'any.required': t('general:modal.validation.fieldRequired'),
        })
    : Joi.custom(getDateValidation(isRequired)).messages({
        'any.required': t('general:modal.validation.fieldRequired'),
      });
