import moment from 'moment';
import { FieldValues } from 'react-hook-form';

import type { ISimpleFilter } from 'hooks/useFilters.hook';
import type { IFilterDefinition } from 'interfaces/Filters.interface';

type TDateFilterSelect = { from: Date; to: Date };

const getValueAsString = (
  value: string | number | boolean | Date | null
): string => {
  if (typeof value === 'string') {
    return value;
  }
  if (typeof value === 'boolean') {
    return value ? 'true' : 'false';
  }
  if (typeof value === 'number') {
    return `${value}`;
  }

  if (typeof value?.getMonth === 'function') {
    return value.toISOString();
  }
  return '';
};

const setFromToDateFilter = (
  filterValues: TDateFilterSelect,
  keyFrom = 'start',
  keyTo = 'end'
): ISimpleFilter[] => {
  const filterResponse: ISimpleFilter[] = [];

  const convertDate = (date: Date) => {
    return moment(date).format('YYYY-MM-DD');
  };

  if (filterValues.from && filterValues.to) {
    filterResponse.push({
      key: `${keyFrom}[after]`,
      operator: 'AND',
      value: getValueAsString(convertDate(filterValues.from)),
    });

    filterResponse.push({
      key: `${keyTo}[before]`,
      operator: 'AND',
      value: getValueAsString(convertDate(filterValues.to)),
    });
  } else if (filterValues.from) {
    filterResponse.push({
      key: `${keyFrom}[after]`,
      operator: 'AND',
      value: getValueAsString(convertDate(filterValues.from)),
    });
  } else if (filterValues.to) {
    filterResponse.push({
      key: `${keyTo}[before]`,
      operator: 'AND',
      value: getValueAsString(convertDate(filterValues.to)),
    });
  }

  return filterResponse;
};

const setDateFilter = (
  filterValues: Date | null,
  key: string
): ISimpleFilter[] => {
  const filterResponse: ISimpleFilter[] = [];

  const convertDate = (date: Date) => {
    return moment(date).format('YYYY-MM-DD');
  };

  if (filterValues) {
    filterResponse.push({
      key,
      operator: 'AND',
      value: getValueAsString(convertDate(filterValues)),
    });
  }

  return filterResponse;
};

const setFromToNumberFilter = (
  filterValues: TDateFilterSelect,
  keyFrom = 'from',
  keyTo = 'to'
): ISimpleFilter[] => {
  const filterResponse: ISimpleFilter[] = [];

  if (filterValues.from) {
    filterResponse.push({
      key: `${keyFrom}`,
      operator: 'AND',
      value: getValueAsString(filterValues.from),
    });
  }
  if (filterValues.to) {
    filterResponse.push({
      key: `${keyTo}`,
      operator: 'AND',
      value: getValueAsString(filterValues.to),
    });
  }

  return filterResponse;
};

export const transformNestedValuesToDispatchValues = (
  values: FieldValues,
  filterDefinitionList: IFilterDefinition[]
): ISimpleFilter[] => {
  const filterResponse: ISimpleFilter[] = [];

  // todo: transform more complex values with filterDefinitionList
  Object.entries(values).forEach(([key, value]) => {
    if (key && value !== undefined && value !== '') {
      const filter = filterDefinitionList.find(
        (definition) => definition.key === key
      );
      const filterValues = value as TDateFilterSelect;
      if (filter?.customFilterResponseFactory) {
        const response = filter?.customFilterResponseFactory(
          filterDefinitionList,
          value
        );
        response.forEach((i) => filterResponse.push(i));
      } else {
        const filterKey = filter?.customApiKey ? filter.customApiKey : key;
        switch (filter?.filterType) {
          case 'fromToDate': {
            const response = setFromToDateFilter(
              filterValues,
              filter.dateRangePickerData?.keyFrom,
              filter.dateRangePickerData?.keyTo
            );
            response.forEach((i) => filterResponse.push(i));
            break;
          }
          case 'datePicker':
          case 'date':
            filterResponse.push(...setDateFilter(value, filterKey));
            break;
          case 'fromToNumber': {
            const responseData = setFromToNumberFilter(
              filterValues,
              filter.rangePickerData?.keyFrom,
              filter.rangePickerData?.keyTo
            );
            responseData.forEach((i) => filterResponse.push(i));
            break;
          }
          case 'multiSelect':
          case 'multiSelectFreeFlow':
            (value as string[]).forEach((selectValue) =>
              filterResponse.push({
                key: filterKey,
                operator: 'OR',
                value: selectValue,
              })
            );
            break;
          case 'contactMultiSelect':
            (value as string[]).forEach((selectValue) =>
              filterResponse.push({
                key: filterKey,
                operator: 'OR',
                value: selectValue,
              })
            );
            break;
          case 'switch':
            // if switch is not nullable and value is false, do not add to filter
            if (
              (!filter.switchData || !filter.switchData.isNullable) &&
              !value
            ) {
              break;
            }
            if (typeof value === 'boolean') {
              filterResponse.push({
                key: filter?.customProperty
                  ? `${filterKey}[${filter?.customProperty}]`
                  : filterKey,
                operator: 'AND',
                value: getValueAsString(value as boolean),
              });
            }
            break;
          case 'input':
          case 'select':
          default: {
            filterResponse.push({
              key: filter?.customProperty
                ? `${filterKey}[${filter?.customProperty}]`
                : filterKey,
              operator: 'AND',
              value: getValueAsString(
                value as string | number | boolean | null
              ),
            });
          }
        }
      }
    }
  });

  return filterResponse;
};
