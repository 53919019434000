import { styled } from 'styled-components';
import { Utils } from 'team-hero-ui';

import { HighlightCellStyled } from 'components/cellRenderers/Common.styled';

const { flexFn, pxToRem } = Utils;

export const TimesheetDurationStyled = styled(HighlightCellStyled)`
  ${flexFn('flex-start')}
  height: 100%;
  width: 100%;
  padding: ${pxToRem([0, 5, 0, 0])};
`;

export const TimesheetSeparatorStyled = styled.div`
  width: ${pxToRem(20)};
`;
