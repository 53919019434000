import { ReactNode, useEffect, useMemo } from 'react';

import type { IFilterDefinition } from 'interfaces/Filters.interface';
import type { IUseFiltersReturn } from 'hooks/useFilters.hook';
import type { IUseToggleReturn } from 'hooks/useToggle.hook';
import { FilterPanel } from '../FilterPanel';

interface IUseFilterPanelArgs {
  filterData?: IUseFiltersReturn;
  filterDefinitionList?: IFilterDefinition[];
  filterTopSection?: ReactNode;
  initialIsFilterVisible?: boolean;
  visibility: IUseToggleReturn;
}

const useFilterPanel = ({
  filterData,
  filterTopSection,
  filterDefinitionList,
  initialIsFilterVisible = false,
  visibility,
}: IUseFilterPanelArgs) => {
  useEffect(() => {
    if (initialIsFilterVisible) {
      visibility.on();
    }
  }, [initialIsFilterVisible, visibility]);

  const FilterPanelComponent = useMemo(() => {
    if (!filterData || !filterDefinitionList?.length) {
      return null;
    }

    return (
      <FilterPanel
        filterTopSection={filterTopSection}
        filterDefinitionList={filterDefinitionList}
        filterData={filterData}
        hidden={!visibility.toggleValue}
      />
    );
  }, [
    filterData,
    filterDefinitionList,
    filterTopSection,
    visibility.toggleValue,
  ]);

  return {
    FilterPanelComponent,
  };
};

export default useFilterPanel;
