import { ReactElement, ReactNode, useEffect, useMemo } from 'react';

import type { IUseFiltersReturn } from 'hooks/useFilters.hook';
import type { TFiltersDisplayType } from '../FilterPanel.type';
import type { IFilterDefinition } from 'interfaces/Filters.interface';
import { useToggle } from 'hooks/useToggle.hook';
import useFilterModal from './useFilterModal.hook';
import useFilterPanel from './useFilterPanel.hook';

interface IUseFilterComponentArgs {
  filterData?: IUseFiltersReturn;
  filterDefinitionList?: IFilterDefinition[];
  filterTopSection?: ReactNode;
  type?: TFiltersDisplayType;
  initialIsFilterVisible?: boolean;
  onClose?: () => void;
}

interface IUseFilterComponentReturn {
  FilterComponent: ReactElement | null;
  FilterChipsComponent: ReactElement | null;
  isFilterVisible: boolean;
  toggleFilterVisibility: () => void;
  setFilterVisible: () => void;
  filtersAreSet?: boolean;
}

const useFilterComponent = ({
  filterData,
  filterTopSection,
  filterDefinitionList,
  type = 'panel',
  initialIsFilterVisible = false,
  onClose,
}: IUseFilterComponentArgs): IUseFilterComponentReturn => {
  const { on, off, toggle, toggleValue } = useToggle(initialIsFilterVisible);

  const visibility = useMemo(
    () => ({ on, off, toggle, toggleValue }),
    [off, on, toggle, toggleValue]
  );

  const { FilterModalComponent, FilterChipsComponent } = useFilterModal({
    filterData,
    filterDefinitionList,
    visibility,
    onClose,
  });

  const { FilterPanelComponent } = useFilterPanel({
    filterData,
    filterTopSection,
    filterDefinitionList,
    visibility,
  });

  useEffect(() => {
    if (initialIsFilterVisible) {
      on();
    }
  }, [initialIsFilterVisible, on]);

  const FilterComponent = useMemo(() => {
    if (!filterData || !filterDefinitionList?.length) {
      return null;
    }

    return type === 'panel' ? FilterPanelComponent : FilterModalComponent;
  }, [
    FilterModalComponent,
    FilterPanelComponent,
    filterData,
    filterDefinitionList?.length,
    type,
  ]);

  const filtersAreSet = useMemo(() => {
    if (!filterData) {
      return false;
    }

    return filterData.filters.some((filter) => filter.value !== undefined);
  }, [filterData]);

  return {
    FilterComponent,
    FilterChipsComponent,
    isFilterVisible: toggleValue,
    toggleFilterVisibility: toggle,
    setFilterVisible: on,
    filtersAreSet,
  };
};

export default useFilterComponent;
