import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Chip } from 'team-hero-ui';

import type { IFilterChipStyled } from './FilterChip.types';
import { useGetSelectExternalOptionValues } from 'hooks/getSelectExternalOptionValues/getSelectExternalOptionValues.hook';
import { useDateFormat } from 'hooks/useDateFormat.hook';
import { ChipStyled } from './FilterChip.styled';

const FilterChip: FC<IFilterChipStyled> = ({
  filter,
  filterDefinition,
  onClearFilter,
  chipStyle = 'filter',
}) => {
  const { t } = useTranslation();
  const { formatDate } = useDateFormat();

  const { data: externalSelectItems } = useGetSelectExternalOptionValues(
    filterDefinition.externalValueType
  );

  const getSwitchLabel = () => {
    const isTrue = filter.value === true || filter.value === 'true';

    const value = isTrue ? t('common.yes') : t('common.no');

    return `${filterDefinition.label}: ${value}`;
  };

  const getExternalSelectLabel = () => {
    const selectedValue = externalSelectItems.find(
      (item) => item.value === filter.value
    );

    return `${filterDefinition.label}: ${selectedValue?.label}`;
  };

  const getSelectLabel = () => {
    const selectData =
      filterDefinition.multiSelectData || filterDefinition.selectData;
    const value =
      selectData?.values?.find(
        (multiSelectValue) => multiSelectValue.value === filter.value
      )?.label || '';

    return `${filterDefinition.label}: ${value}`;
  };

  const getFromToDateLabel = () => {
    const fromToString = filter.key.includes('before')
      ? filterDefinition.dateRangePickerData?.toLabel
      : filterDefinition.dateRangePickerData?.fromLabel;
    return `${filterDefinition.label} ${fromToString}: ${formatDate(
      filter.value as string
    )}`;
  };

  const getNumberFromToLabel = () => {
    const fromToString = filter.key.includes(
      filterDefinition.rangePickerData?.keyTo || ''
    )
      ? t('filter.labels.till')
      : t('filter.labels.from');
    return `${filterDefinition.label} ${fromToString}: ${filter.value}`;
  };

  const getDateLabel = () => {
    return `${filterDefinition.label}: ${formatDate(filter.value as string)}`;
  };

  const getInputLabel = () => {
    const value = filter.value?.toString() || '';
    return `${filterDefinition.label}: ${value}`;
  };

  const getLabel = () => {
    const isSwitch = filterDefinition.filterType === 'switch';
    const isExternalSelect = filterDefinition.externalValueType;
    const isSelect =
      (filterDefinition.multiSelectData || filterDefinition.selectData) &&
      filterDefinition.filterType !== 'multiSelectFreeFlow';
    const isFromToDate = filterDefinition.filterType === 'fromToDate';
    const isAge = filterDefinition.filterType === 'fromToNumber';
    const isDate =
      filterDefinition.filterType === 'date' ||
      filterDefinition.filterType === 'datePicker';

    if (isSwitch) {
      return getSwitchLabel();
    } else if (isExternalSelect) {
      return getExternalSelectLabel();
    } else if (isSelect) {
      return getSelectLabel();
    } else if (isFromToDate) {
      return getFromToDateLabel();
    } else if (isDate) {
      return getDateLabel();
    } else if (isAge) {
      return getNumberFromToLabel();
    } else {
      return getInputLabel();
    }
  };

  return (
    <ChipStyled data-component='single-filter-chip'>
      <Chip
        name={getLabel()}
        onDelete={
          onClearFilter
            ? () => onClearFilter(filter.key, filter.value)
            : () => {}
        }
        type='notSet'
        disabled={!onClearFilter}
        styleType={chipStyle}
        value={String(filter.value)}
      />
    </ChipStyled>
  );
};

export default FilterChip;
