import { FC, useState, useRef, useEffect, useCallback } from 'react';
import moment from 'moment';
import {
  IconButton,
  Icons,
  Loader,
  SidePopup,
  SignatureArea,
  ReactSignatureCanvas,
  Theme,
  Box,
  Button,
} from 'team-hero-ui';
import { useTranslation } from 'react-i18next';
import { useFieldArray, useForm } from 'react-hook-form';

import type {
  IApproveTimesheet,
  IClientApprovalModalProps,
  ITimesheetApprovalModalState,
} from 'components/Modals/ClientApprovalModal/ClientApprovalModal.types';
import {
  ApprovalListWrapperStyled,
  ApproveButtonStyled,
  ClientApprovalButtonSectionStyled,
  ClientApprovalButtonStyled,
  ClientApprovalContainerStyled,
  ClientApprovalHeaderStyled,
  ClientApprovalOperationsSectionStyled,
  TitleStyled,
  SignatureAreaWrapperStyled,
} from 'components/Modals/ClientApprovalModal/ClientApprovalModal.styled';
import ModalValidationWrapper from 'components/Modals/AddEditModal/ModalValidationWrapper';
import { useDateFormat } from 'hooks/useDateFormat.hook';
import { dateService } from 'services/date/date.service';
import ClientApprovalModalList from 'components/Modals/ClientApprovalModal/ClientApprovalModalList';
import { useClientApprovalStrategies } from 'components/Modals/ClientApprovalModal/useClientApprovalStrategies.hook';
import getBlackPenColorCanvas from 'helpers/signature/signature.helper';

const ClientApprovalModal: FC<IClientApprovalModalProps> = ({
  isOpen,
  title,
  timesheets,
  isLoading = false,
  onClose,
}) => {
  const { t } = useTranslation();
  const { formatDate } = useDateFormat();
  const sigPadRef = useRef<ReactSignatureCanvas>(null);
  const { updateTimesheetApprovalStrategy } = useClientApprovalStrategies();
  const [isSignatureError, setIsSignatureError] = useState(false);
  const [isProcessing, setIsProcessing] = useState<boolean>(false);

  const { handleSubmit, reset, setValue, control } =
    useForm<ITimesheetApprovalModalState>({
      defaultValues: {
        approveTimesheets: [],
      },
    });

  const { fields: timesheetsFields, update } = useFieldArray({
    control,
    name: 'approveTimesheets',
  });

  const getTimesheetDuration = useCallback(
    (start?: string, end?: string): string => {
      if (start && end) {
        const diff = dateService(end).value().diff(dateService(start).value());

        return formatDate(moment.utc(diff), 'time');
      }

      return '-';
    },
    [formatDate]
  );

  useEffect(() => {
    const formValues: IApproveTimesheet[] = timesheets.map((timesheet) => ({
      timesheetId: timesheet.id,
      contactId: timesheet.contact?.id,
      avatar: timesheet.contact?.avatar || '',
      firstName: timesheet.contact?.firstName || '',
      lastName: timesheet.contact?.lastName || '',
      start: new Date(timesheet.start || ''),
      end: new Date(timesheet.end || ''),
      duration: getTimesheetDuration(timesheet.start, timesheet.end),
      isBreak: timesheet.isBreak || false,
      position: timesheet.shift?.position || '',
      note: timesheet.note || '',
      isApproved: timesheet.isExternallyApproved || false,
    }));

    reset({ approveTimesheets: formValues });
  }, [timesheets, isOpen, reset, getTimesheetDuration]);

  const toggleSelectAll = (isApproved: boolean) => {
    const currentApproveTimesheets = timesheetsFields.map(
      (approveTimesheet) => ({
        ...approveTimesheet,
        isApproved,
      })
    );

    setValue('approveTimesheets', currentApproveTimesheets);
  };

  const getOriginalNote = (timesheetId: number) => {
    const originalTimesheet = timesheets.find(
      (timesheet) => timesheet.id === timesheetId
    );

    return originalTimesheet?.note || '';
  };

  const setIsApproved = (index: number, value: boolean) => {
    const currentTimesheet = timesheetsFields[index];

    update(index, {
      ...currentTimesheet,
      note: value ? '' : getOriginalNote(currentTimesheet.timesheetId),
      isApproved: value,
    });
  };

  const getImageTrim = () => {
    if (sigPadRef.current && !sigPadRef.current.isEmpty()) {
      return getBlackPenColorCanvas(sigPadRef.current);
    }

    return null;
  };

  const handleCloseCallback = useCallback(() => {
    reset();
    onClose();
  }, [onClose, reset]);

  const onSubmit = async (state: ITimesheetApprovalModalState) => {
    const imageTrim = getImageTrim();

    if (imageTrim) {
      setIsProcessing(true);
      await updateTimesheetApprovalStrategy(
        imageTrim,
        state.approveTimesheets,
        timesheets
      );
      setIsSignatureError(false);
      setIsProcessing(false);
      handleCloseCallback();
    } else {
      setIsSignatureError(true);
    }
  };

  const handleClearSignature = () => {
    sigPadRef.current?.clear();
  };

  return (
    <SidePopup
      isOpen={isOpen}
      popupDirection='top'
      onClose={handleCloseCallback}
      dataTestId='client-approval-modal'
    >
      <ClientApprovalContainerStyled>
        <ClientApprovalHeaderStyled>
          <IconButton onClick={handleCloseCallback}>
            <Icons.CloseIcon
              svgColor={Theme.colors.primary.white}
              svgSize={40}
            />
          </IconButton>
        </ClientApprovalHeaderStyled>
        <TitleStyled>{title}</TitleStyled>
        {isLoading ? (
          <Loader loaderSize='small' loaderType='static' />
        ) : (
          <>
            <ApprovalListWrapperStyled>
              <ClientApprovalModalList
                control={control}
                setIsApproved={setIsApproved}
                timesheetsFields={timesheetsFields}
              />
              <ClientApprovalButtonSectionStyled>
                <ClientApprovalButtonStyled
                  color='transparentWithBorder'
                  onClick={() => toggleSelectAll(true)}
                >
                  {t('details.modal.selectAll')}
                </ClientApprovalButtonStyled>
                <ClientApprovalButtonStyled
                  color='transparentWithBorder'
                  onClick={() => toggleSelectAll(false)}
                >
                  {t('details.modal.deselectAll')}
                </ClientApprovalButtonStyled>
              </ClientApprovalButtonSectionStyled>
            </ApprovalListWrapperStyled>
            <ClientApprovalOperationsSectionStyled>
              <SignatureAreaWrapperStyled>
                <ModalValidationWrapper
                  text={
                    isSignatureError
                      ? t('work.modals.checkInOutShared.validation.signature')
                      : null
                  }
                >
                  <SignatureArea sigPadRef={sigPadRef} />
                </ModalValidationWrapper>
                <Box display='flex' justifyContent='end' mt={2}>
                  <Button
                    color='transparentWithBorder'
                    onClick={handleClearSignature}
                  >
                    {t('modal.labels.clearSignature')}
                  </Button>
                </Box>
              </SignatureAreaWrapperStyled>
              <ApproveButtonStyled
                color='green'
                onClick={handleSubmit(onSubmit)}
                disabled={isProcessing}
                data-test-id='approve-button'
              >
                {t('details.modal.iApprove')}
              </ApproveButtonStyled>
            </ClientApprovalOperationsSectionStyled>
          </>
        )}
      </ClientApprovalContainerStyled>
    </SidePopup>
  );
};

export default ClientApprovalModal;
