import { ChangeEvent, FC, MouseEventHandler } from 'react';
import {
  DragDropContext,
  Droppable,
  Draggable,
  DraggingStyle,
  DropResult,
  NotDraggingStyle,
} from '@hello-pangea/dnd';
import { useTranslation } from 'react-i18next';
import { Dropdown, DropdownItem, Icons, Theme, Switch } from 'team-hero-ui';

import { IMenuElement } from 'components/TableView/hooks/useColumnList.hook';
import {
  ShowAllButtonStyled,
  ShowColumnsTitleStyled,
  FormControlLabelStyled,
  DropdownItemLabelStyled,
  DropdownItemStyled,
} from './ColumnSelectionDropdown.styled';

interface IColumnSelectionDropdownProps {
  items: IMenuElement[];
  handleChange: (
    event: ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => void;
  handleOnAllClick: MouseEventHandler<HTMLButtonElement> | undefined;
  onDragEnd: (res: DropResult) => void;
}

const getListStyle = (isDraggingOver: boolean) => ({
  backgroundColor: isDraggingOver
    ? Theme.colors.others.green.green1
    : Theme.colors.primary.white,
});

const getItemStyle = (
  isDragging: boolean,
  draggableStyle: DraggingStyle | NotDraggingStyle | undefined
) => ({
  background: isDragging
    ? Theme.colors.others.green.green3
    : Theme.colors.primary.white,
  ...draggableStyle,
});

export const ColumnSelectionDropdown: FC<IColumnSelectionDropdownProps> = ({
  items,
  handleChange,
  handleOnAllClick,
  onDragEnd,
}) => {
  const { t } = useTranslation();
  return (
    <Dropdown
      styleType='toolbar'
      iconColor={Theme.colors.greyShades.grey2}
      borderColor={Theme.colors.greyShades.grey5}
      label={
        <Icons.ColumnIcon
          svgColor={Theme.colors.greyShades.grey2}
          svgSize={16}
        />
      }
      dataTestId={'column-selection-dropdown'}
    >
      <ShowColumnsTitleStyled>
        {t('table.addons.header.showColumns')}
      </ShowColumnsTitleStyled>

      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId='droppable'>
          {(provided, snapshot) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              style={getListStyle(snapshot.isDraggingOver)}
            >
              <>
                {items?.map((item: IMenuElement, index) => {
                  return (
                    item.displayInMenu && (
                      <Draggable
                        key={item.key}
                        draggableId={item.key}
                        index={index}
                      >
                        {(itemProvided, itemSnapshot) => (
                          <DropdownItemStyled
                            key={item.key}
                            ref={itemProvided.innerRef}
                            {...itemProvided.draggableProps}
                            {...itemProvided.dragHandleProps}
                            style={getItemStyle(
                              itemSnapshot.isDragging,
                              itemProvided.draggableProps.style
                            )}
                          >
                            <FormControlLabelStyled
                              control={
                                <Switch
                                  size='small'
                                  onChange={handleChange}
                                  checked={item.visibility}
                                  name={item.key}
                                  color='primary'
                                />
                              }
                              label={
                                <DropdownItemLabelStyled>
                                  <div>{item.label}</div>
                                  <Icons.MenuIcon
                                    svgSize={16}
                                    svgColor={Theme.colors.greyShades.grey1}
                                  />
                                </DropdownItemLabelStyled>
                              }
                            />
                          </DropdownItemStyled>
                        )}
                      </Draggable>
                    )
                  );
                })}
                {provided.placeholder}
              </>
            </div>
          )}
        </Droppable>
      </DragDropContext>
      <DropdownItem>
        <ShowAllButtonStyled
          onClick={handleOnAllClick}
          data-test-id={'show-all-button'}
        >
          {t('table.addons.header.showAll')}
        </ShowAllButtonStyled>
      </DropdownItem>
    </Dropdown>
  );
};
