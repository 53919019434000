import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import type { ITimesheetsListProps } from './TimesheetList.types';
import type { ISimpleFilter } from 'hooks/useFilters.hook';
import type { IProjectTimesheet } from 'interfaces/ProjectTimesheet.interface';
import {
  paginatedTimesheetsSelector,
  setItemsPerPage,
  setNextPage,
  setSorting,
  setMenuColumnList,
} from 'redux/table/timesheets/timesheets.slice';
import {
  timesheetsColumnsFactory,
  columnsOffset,
} from 'config/tableColumnConfigurations/TimesheetsColumns.config';
import { useAppSelector } from 'store/hooks';
import { TableView } from 'components/TableView/TableView';
import { usePagination } from 'hooks/usePagination.hook';
import { useSort } from 'components/TableView/hooks/useSort.hook';
import { createPaginationApiArgs } from 'services/helper/getPaginationParams.helper';
import { useRowSelection } from 'components/TableView/hooks/useRowSelection.hook';
import { useGetProjectTimesheetsQuery } from 'services/teamHeroApi.service';
import { getDuringStringHelper } from 'helpers/dateTime/getDuringString.helper';
import useClientLoginMissionSettings from 'hooks/useClientLoginMissionSettings.hook';
import { useTimesheetsSummaryRow } from 'components/ClientLoginMissionDetails/Tabs/MissionTimesheetsTab/hooks/useTimesheetSummaryRow.hook';
import TimesheetRowEdit from 'components/Table/components/RowAction/TimesheetRowEdit';
import { useClientApprovalModalByIds } from 'pages/LiveMode/hooks/modals/useClientApprovalModalByIds.hook';
import TimesheetBulkEdit from 'components/Table/components/BulkEditRow/TimesheetBulkEdit';

export const TimesheetsListTable: FC<ITimesheetsListProps> = ({
  id,
  projectId,
  filterPropertyName,
  headerLeftContent,
  timesheetSettings,
  timesheetCenterHeader,
}) => {
  const { t } = useTranslation('general');
  const { enableClientContacts } = useClientLoginMissionSettings({
    missionId: id,
  });
  const { clientApprovalModal, openClientApprovalModalWithIds } =
    useClientApprovalModalByIds({
      modalTitle: t('table.action.timesheetsApprove'),
    });

  const filters: ISimpleFilter[] = [
    { key: filterPropertyName, operator: 'AND', value: id },
    { key: 'status', operator: 'AND', value: 'approved' },
    {
      key: 'during',
      operator: 'AND',
      value: getDuringStringHelper(
        timesheetSettings.startDate.toDate(),
        timesheetSettings.endDate.toDate()
      ),
    },
  ];

  const { pagination, sorting } = useAppSelector(paginatedTimesheetsSelector);

  const {
    data: timesheets,
    isLoading,
    isFetching,
    isError,
  } = useGetProjectTimesheetsQuery({
    ...createPaginationApiArgs(pagination, sorting),
    filters,
  });

  const paginationData = usePagination(
    1,
    setNextPage,
    setItemsPerPage,
    paginatedTimesheetsSelector
  );

  const sortingData = useSort(setSorting, paginatedTimesheetsSelector);

  const { rowSelectionProps, bulkEdit } = useRowSelection<IProjectTimesheet>(
    timesheets?.items,
    undefined,
    (selectedRowsIds: number[]) => (
      <TimesheetBulkEdit
        selectedRowsIds={selectedRowsIds}
        onBulkClientApproval={openClientApprovalModalWithIds}
      />
    )
  );

  const { summaryBottomRow } = useTimesheetsSummaryRow({
    enableClientContacts,
    hidden: !timesheets?.items.length || !projectId,
    projectId,
    missionId: id,
    startDate: timesheetSettings.startDate,
    endDate: timesheetSettings.endDate,
    timesheets: timesheets?.items || [],
  });

  if (isError) {
    throw new Error(t('errors:errorOccurred'));
  }

  return (
    <>
      {clientApprovalModal}
      <TableView
        columns={timesheetsColumnsFactory({
          displayContactAsDefault: enableClientContacts,
          t,
          rowSelectionProps,
        })}
        headerLeftAdditional={headerLeftContent}
        headerCenterSection={timesheetCenterHeader}
        bulkEditRow={bulkEdit}
        data={timesheets?.items || []}
        isLoading={isLoading}
        editRowComponent={() => (
          <TimesheetRowEdit
            rowSelectionProps={rowSelectionProps}
            onClientApprovalClick={(idToOpen) =>
              openClientApprovalModalWithIds([idToOpen])
            }
          />
        )}
        isFetching={isFetching}
        itemsTotalCount={timesheets?.totalCount || 0}
        pagination={paginationData}
        rowIdMenuOpen={rowSelectionProps.rowIdMenuOpen}
        columnsOffset={columnsOffset}
        sorting={sortingData}
        bottomRowRenderer={summaryBottomRow}
        isMin={false}
        paginationSelector={paginatedTimesheetsSelector}
        setColumnListDispatch={setMenuColumnList}
        disableColumnSelection={true}
      />
    </>
  );
};
