import type { TRootState } from 'store';
import type { ITableSelector } from 'interfaces/Table/selector.interface';
import { createTableSelectorSlice } from 'redux/table/helper/createTableSelector.slice';
import {
  itemsPerPageDefault,
  currentPageDefault,
} from 'redux/table/consts/tableDefaults.consts';

export const timesheetsSlice = createTableSelectorSlice(
  'paginatedTimesheets',
  currentPageDefault,
  itemsPerPageDefault,
  { columnKey: 'start', direction: 'asc' }
);

const { actions, reducer } = timesheetsSlice;

export const {
  setPagination,
  setNextPage,
  setSorting,
  setItemsPerPage,
  setFilters,
  setInitialFiltersSet,
  setMenuColumnList,
} = actions;

export const paginatedTimesheetsSelector = (
  state: TRootState
): ITableSelector => state.tableTimesheets;

export default reducer;
