import { FC } from 'react';
import { Page, View, Document, StyleSheet, Image } from '@react-pdf/renderer';
import Html from 'react-pdf-html';

import type { IHtmlDocumentPdfProps } from './HtmlDocumentPdf.types';
import styles from 'components/PdfDocuments/common/styles';
import PdfImage from 'components/PdfDocuments/common/PdfImage';

const htmlPrefix = `
<html>
  <style>
    body {
      font-family: Helvetica;
      font-size: 11px;
    }
    h1 {
      font-size: 30px;
    }
    h2 {
      font-size: 24px;
    }
    h3 {
      font-size: 18px;
    }
  </style>
  <body>`;
const htmlSuffix = '</body></html>';

const documentStyles = StyleSheet.create({
  backgroundMargin: {
    marginBottom: 120,
    marginRight: 120,
  },
});

const HtmlDocumentPdf: FC<IHtmlDocumentPdfProps> = ({
  body,
  backgroundImage,
}) => {
  return (
    <Document>
      <Page size='A4' style={[styles.fullPage, styles.paddingXL]}>
        {backgroundImage?.src && (
          <PdfImage
            src={backgroundImage.src}
            isLocal={backgroundImage.isLocal}
            style={[styles.pageBackground, documentStyles.backgroundMargin]}
            isFixed
            isPublicUrl={false}
          />
        )}
        <View>
          <Html
            resetStyles
            renderers={{
              figure: (props) => {
                return (
                  <View
                    {...props}
                    style={{ display: 'flex', justifyContent: 'flex-start' }}
                  />
                );
              },
              img: (props) => {
                const src = props.element.attributes.src;
                const width = props.element.attributes.width || undefined;
                const margin = props.element.style[0]?.margin || undefined;
                const marginLeft =
                  props.element.style[0]?.marginLeft || undefined;
                const marginRight =
                  props.element.style[0]?.marginRight || undefined;
                return (
                  <Image
                    src={src}
                    style={{
                      width: `${width}%`,
                      margin: margin,
                      marginLeft: marginLeft,
                      marginRight: marginRight,
                    }}
                  />
                );
              },
            }}
          >{`${htmlPrefix}${body}${htmlSuffix}`}</Html>
        </View>
      </Page>
    </Document>
  );
};

export default HtmlDocumentPdf;
