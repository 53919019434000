import type { TFeatureFlags } from 'components/FeatureFlag/featureFlag.type';
import { useGetPublicSystemSettingsQuery } from 'services/publicTeamHeroApi.service';
import { useCheckAuth } from 'hooks/auth/useCheckAuth.hook';

interface IUseFlagsReturn {
  isFeatureFlagEnabled: (flag: TFeatureFlags) => boolean;
  isLoading: boolean;
}

/**
 * useFlags hook
 * @description A hook that returns the feature flags
 */
export const useFlags = (): IUseFlagsReturn => {
  const { check } = useCheckAuth();
  const { data: systemSettingsData, isLoading } =
    useGetPublicSystemSettingsQuery();

  const flags: Record<TFeatureFlags, boolean> = {
    clientPool: false,
    clientRatings: false,
    clientDashboardRatingsCard: false,
    clientDashboardApplicationsCard: false,
    clientDocuments: true,
    '2factorAuth':
      (systemSettingsData?.settings?.two_factor_enabled &&
        check(systemSettingsData?.settings?.two_factor_roles || [])) ||
      false,
  };

  const isFeatureFlagEnabled = (flag: TFeatureFlags): boolean => {
    return flags[flag];
  };

  return { isFeatureFlagEnabled, isLoading };
};
