import { FC } from 'react';

import type { IRowSelectionProps } from 'components/TableView/hooks/useRowSelection.hook';
import { RowAction } from '../RowAction';
import { TimesheetAdditionalRowActions } from './TimesheetAdditionalRowActions';

interface ITimesheetRowEditProps {
  rowSelectionProps: IRowSelectionProps;
  onClientApprovalClick: (id: number) => void;
}

const TimesheetRowEdit: FC<ITimesheetRowEditProps> = ({
  rowSelectionProps: {
    areCheckboxesVisible,
    setRowIdMenuOpen,
    onHandleRowSelection,
    selectedRowIds,
    onMenuClose,
    toggleCheckboxesVisibility,
    rowIdMenuOpen,
  },
  onClientApprovalClick,
}) => {
  return !areCheckboxesVisible && rowIdMenuOpen ? (
    <RowAction
      onCloseMore={onMenuClose}
      onSelect={() => {
        setRowIdMenuOpen(null);
        toggleCheckboxesVisibility();
        onHandleRowSelection(rowIdMenuOpen);
      }}
      isSelected={!!selectedRowIds.length}
      id={rowIdMenuOpen}
      additionalActions={
        <TimesheetAdditionalRowActions
          rowIdMenuOpen={rowIdMenuOpen}
          onClientApprovalClick={onClientApprovalClick}
        />
      }
    />
  ) : null;
};

export default TimesheetRowEdit;
